import React from 'react'
import Crop from './../assets/crop.svg'
import Mens from './../assets/mens.svg'
import WOmens from './../assets/womens.svg'
import Shoulder from './../assets/shoulder.svg'
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  },
};
  const Scroller = () => {
    return (
      <div >
      <Carousel responsive={responsive}>
         <img src={Crop} alt='product' className='w-full'/>
          <img src={WOmens} alt='product'className='w-full'/>
          <img src={Mens} alt='product'className='w-full'/>
           <img src={Shoulder } alt='product'className='w-full'/>              

        </Carousel>
      </div>
    );
  };
  
  export default Scroller;