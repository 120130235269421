import './App.css';
// import Nav from './components/Navbar';
import Home from './pages/home';
function App() {
  return (
    <div className="App">
     
      {/* <Nav/> */}
       <Home/>
  
         
    </div>
  );
}

export default App;
